<template>
  <div>
    <button
      v-if="dataList.length > 0 && !isInterviewer"
      class="btn btn-sm btn-blue mr-2"
      @click.prevent="openPatternModal(dataList[0])"
    >
      {{ i18n("Custom.TestReport") }}
    </button>

    <el-dialog
      :title="i18n('Custom.CorrespondingTemplate')"
      :visible.sync="dialogVisible"
      width="80%"
      top="40px"
    >
      <TraitComponent :patternModal="patternModal" />
      <span slot="footer" class="dialog-footer">
        <el-button type="info" @click="dialogVisible = false">{{
          i18n("Basic.Close")
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import TraitComponent from "@/components/Result/TraitComponent";
export default {
  name: "Home",
  data() {
    return {
      dialogVisible: false,
      screen: null, // loading遮罩
      patternModal: {}, //匹配模板modal
      dataList: [],
      isInterviewer:false //是否為面試人員
    };
  },
  components: {
    TraitComponent
  },
  methods: {
    // 多國語系
    i18n(keyStr) {
      return this.$t(keyStr);
    },
    getDataList() {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/Self`;

      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.dataList = response.data.Data;
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },

    // 檢視對應模板
    openPatternModal(row) {
      this.screen = this.openLoading();
      const url = `${window.BaseUrl.api}/Eva/Result/Self/Pattern/${row.EvaluationPersonGuid}`;
      this.$http
        .get(url)
        .then((response) => {
          if (response.data.ErrorMessage) {
            this.$notify({
              title: "錯誤",
              message: response.data.ErrorMessage,
              type: "error",
              duration: 0,
            });
          } else {
            this.patternModal = {
              ...response.data.Data,
            };

            this.dialogVisible = true;
            // $("#patternModal").modal("show");
          }
          this.screen.close();
        })
        .catch((error) => {
          this.$notify({
            title: "錯誤",
            message: error.response.data.ErrorMessage,
            type: "error",
            duration: 0,
          });
          this.screen.close();
        });
    },
  },
  created() {
    this.getDataList();
    let roles = localStorage.getItem('RoleName');
    let targetRole = "面試人員";

      // 創建正規表達式來精確匹配目標角色
    let regex = new RegExp(`(^|,)${targetRole}($|,)`);

      if (regex.test(roles)) {
        this.isInterviewer = true;
      } else {
        this.isInterviewer = false;
      }
  },
};
</script>
